<template>
    <div class="sidebar_list">
        <div>
            <div
                v-for="(group, index) in sortedList"
                :key="`group_${index}`"
                class="sidebar_item">
                <GroupLabel :group="group" />
                <div v-for="(item) in group.data" :key="`chat_${item.id}`" class="ch_item">
                    <ChatContact :chat="item" />
                </div>
            </div>
            <infinite-loading
                @infinite="getChatList"
                ref="chat_list_inf"
                v-bind:distance="20">
                <div slot="spinner">
                    <a-spin
                        size="small"
                        style="margin-top: 10px;" />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
            <a-empty v-if="!loading && chatList.length === 0" class="mt-4" description="Список пуст"/>
        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import ChatContact from '../ChatContact.vue'
import GroupLabel from './GroupLabel.vue'
import ChatEventBus from '../../utils/ChatEventBus'
export default {
    name: "ChatList",
    components: {
        ChatContact,
        InfiniteLoading,
        GroupLabel
    },
    computed: {
        ...mapState({
            chatList: state => state.chat.chatList,
            chatListNext: state => state.chat.chatListNext,
            chatListPage: state => state.chat.chatListPage,
            isMobile: state => state.isMobile
        }),
        sortedList() {
            const list = [...this.chatList].map(item => {
                return {
                    ...item,
                    key: this.$moment(item.last_sent).format('YYYY-MM-DD')
                }
            })

            const res = list.reduce((accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
                const keyObjectPosition = accumulator.findIndex((item) => item.key === key)
                if (keyObjectPosition >= 0) {
                    accumulator[keyObjectPosition].data.push(currentValue)
                    return accumulator    
                } else {
                    return accumulator.concat({ data: [currentValue], key: key })
                }
            }, [])

            return res
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        ...mapActions({
            getSidebarChat: 'chat/getSidebarChat'
        }),
        async getChatList($state) {
            if(this.chatListNext) {
                try {
                    this.loading = true
                    const res = await this.getSidebarChat()
                    if(res.next) {
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            } else
                $state.complete()
        },
        reload() {
            if(this.$refs['chat_list_inf']?.stateChanger)
                this.$refs['chat_list_inf'].stateChanger.reset()
            
            this.$store.commit('chat/CLEAR_CHAT_LIST')
        }
    },
    mounted() {
        ChatEventBus.$on('updateChatList', () => {
            this.reload()
        })
        if(this.isMobile) {
            window.onfocus = () => {
                this.reload()
            }
        }
    },
    beforeDestroy() {
        ChatEventBus.$off('updateChatList')
    }
}
</script>