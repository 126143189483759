<template>
    <transition name="slide">
        <div
            v-if="replyMessage"
            class="truncate reply_nav lg:px-4 py-1 w-full">
            <div class="flex items-center truncate reply_nav__wrapper">
                <div class="mr-3 blue_color text-lg">
                    <i class="fi fi-rr-undo"></i>
                </div>
                <div class="replay_nav_body truncate w-full pr-2">
                    <div class="text-xs font-semibold">
                        {{replyMessage.message_author.full_name}}
                    </div>
                    <div
                        v-if="replyMessage.text.length"
                        class="replay_nav_text truncate text-sm ">
                        <span
                            class="truncate"
                            v-html="replyMessageText" />
                    </div>
                    <!-- <template v-else>
                        <template v-if="replyMessage.share">
                            <div class="text-sm">
                                {{$t(replyMessage.share.model)}}
                            </div>
                        </template>
                        <div v-else class="text-sm">
                            {{$t('chat.file_and_image')}}
                        </div>
                    </template> -->
                </div>
            </div>
            <a-button
                @click="replyRemove"
                type="ui"
                ghost
                shape="circle"
                flaticon
                size="large"
                icon="fi-rr-circle-xmark" />
        </div>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: "ChatReplyMessage",
    computed: {
        ...mapState({
            activeChat: state => state.chat.activeChat
        }),
        ...mapGetters({
            getReplyMessage: 'chat/replyMessage'
        }),
        replyMessage() {
            return this.getReplyMessage(this.activeChat.chat_uid)
        },
        replyMessageText() {
            const reply = this.replyMessage
            if(reply?.text) {
                return reply.text.replace(/&nbsp;/g, '').replace(/\<br\s*[\/]?>/gi, ' ')
            }
            return ''
        }
    },
    methods: {
        ...mapMutations({
            DELETE_REPLY_MESSAGE: 'chat/DELETE_REPLY_MESSAGE'
        }),
        replyRemove() {
            this.DELETE_REPLY_MESSAGE(this.activeChat.chat_uid)
        }
    }
}
</script>