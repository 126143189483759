<template>
    <div>
        <Conference
            v-if="isConference"
            :messageItem="message" />
        <Workgroup
            v-if="isWorkgroup"
            :messageItem="message" />
        <Task
            v-if="isTask"
            :messageItem="message" />
        <File
            v-if="isFile"
            :message="message" />
        <Comments
            v-if="isComments"
            :messageItem="message" />
        <Sprint
            v-if="isSprint" 
            :messageItem="message" />
        <Order 
            v-if="isOrder" 
            :messageItem="message"/>
        <SharedNews 
            v-if="isNews"
            :message="message"/>
        <SharedEvent 
            v-if="isEvent"
            :message="message"/>
        <SharedTicket
            v-if="isTicket"
            :message="message"/>
    </div>
</template>

<script>
import Task from './Task'
import Sprint from './Sprint'
import Order from './Order'
import File from './File'
import Comments from './Comments'
import Conference from './Conference'
import Workgroup from './Workgroup'
import SharedNews from './SharedNews'
import SharedEvent from './SharedEvent'
import SharedTicket from './SharedTicket'


export default {
    components: {
        Task,
        File,
        Comments,
        Workgroup,
        Conference,
        Sprint,
        Order,
        SharedNews,
        SharedEvent,
        SharedTicket
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    computed: {
        isConference() {
            return this.message.share.type === 'meetings.PlannedMeetingModel'
        },
        isWorkgroup() {
            return this.message.share.type === 'workgroups.WorkGroupModel' ||
                this.message.share.type === 'workgroups.WorkgroupModel'
        },
        isTask() {
            return this.message.share.type === 'tasks.TaskModel'
        },
        isBusinessProcces() {
            return this.message.share.type === 'processes.FinancialApplicationModel'
        },
        isFile() {
            return this.message.share.type === 'files' || 
                this.message.share.type ===  'common.File'
        },
        isComments() {
            return this.message.share.type === 'comments.CommentModel' || 
                this.message.share.type === 'comments'
        },
        isSprint() {
            return this.message.share.type === 'tasks.TaskSprintModel'
        },
        isOrder() {
            return this.message.share.type === 'crm.GoodsOrderModel'
        },
        isNews() {
            return this.message.share.type === 'bpms_common.NewsModel'
        },
        isEvent() {
            return this.message.share.type === 'event_calendar.EventCalendarModel'
        },
        isTicket() {
            return this.message.share.type === 'tickets.TicketModel'
        }
    },
}
</script>
